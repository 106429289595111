import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';
import { navigate, graphql } from 'gatsby'

import SEO from "../../components/seo";
import LayoutSub from "../../components/layoutSub";
import Call from "../../static/svg/icon-call.inline.svg";

function Thanks( {data, location, pageContext} ) {

  const { breadcrumb: { crumbs } } = pageContext;
  const { pathname } = location;

  const { state = {} } = location;

  if (state === null || state === undefined) {
    navigate('/en/404/');
    return null;
  }

  const venue = (state) ? state.venue : "";

  const inputVenue = (() => {
    switch (venue) {
      case "nishiogi": return "Nishiogikubo";
      case "kasai": return "Kasai";
      default: return "";
    }
  })();

  const symptoms = (state.symptom && Array.isArray(state.symptom)) 
  ? (state.symptom).join('、 ')
  : null ;

  const pagetitle = "Thank you for your contact";
  const description = "Thank you for your contact";


   
    return (
        (state) ?


      <div>

        <LayoutSub crumbs={crumbs} pathname={pathname} lang='en' >
          <SEO
            pageurl={pathname}
            pagetitle={pagetitle}
            description={description}
            lang="en"
          />

          <h1 className="heading-b">Thank you for your contact.</h1>

                <div>
          <p>Dear {state.name}</p>
          <p>Thank you for contacting us.  We&apos;ll get back to you within one business day. We&apos;ll reply from           <span className="mx-2">
            <StaticImage
              src="../../images/e1.png"
              alt="email"
              placeholder="tracedSVG"
              layout="constrained"
              loading="eager"
            />
            <StaticImage
              src="../../images/e2.png"
              alt="email"
              placeholder="tracedSVG"
              layout="constrained"
              loading="eager"
            /></span>
            
            Please give us a call if you are in hurry.</p>
            <div className="sm:pointer-events-none">
            <a href={`tel:${data.site.siteMetadata.tel}`.replace(/\s+/g, "")}>
  
              <p className="text-xl underline font-semibold sm:no-underline mb-0 ">
                <Call className="inline h-6 w-6 pb-0.5 ml-1" />
                {data.site.siteMetadata.tel}
              </p>
            </a>
            </div>
            <p className="mt-0 text-sm sm:hidden">(Tap to call)</p>
            <p className="mt-0 mb-1"><span className="font-semibold">telephone reception hours：</span>Mon to Sat 10:00~18:00</p>
            <p>If you come through to the answering machine, please leave a message and we will call you back as soon as possible.</p>

  
          <p className="mt-6">We&apos;ve got the following message.</p>
          <div className="bg-gray-200 p-2">
            <p>Name:{state.name}</p>
            <p>E-mail:{state.email}</p>
            {state.tel && <p>Phone:{state.tel}</p>}
            <p>Location:{inputVenue}</p>
            {state.datetime && <p>Preferred date and time:{state.datetime}</p>}
            {symptoms && <p>What is your problem?:&nbsp;{symptoms }</p> }
            {state.message && <p>Message:<br /><span className="pl-4">{state.message}</span></p> }
          </div>

          </div>


        </LayoutSub>
      </div>

      : 
      null
       
         
    )
}

export default Thanks;

export const query = graphql`
query {
  site {
  siteMetadata {
   tel
}
}
}
`